import { Component, OnInit } from '@angular/core';
import * as pbi from 'powerbi-client';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  tokenReport: any;
  reportId: any;
  service: any;
  params: any;

  constructor(private http: HttpClient, private appRoute: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {

    this.appRoute.paramMap.subscribe(parameter =>{

      if(parameter.get('id') !== null){
       this.params = parameter.get('id');
      } else {
       this.params = '';
      }

      this.http.get<any>(`https://apigateway.outsourcingcos.com/v1/ms/reportes/api/report/tokens?key=${this.params}`).subscribe(resp =>{
      if(resp.data.token !== ''){
        this.tokenReport = resp.data.token;
        this.reportId =resp.data.reporte_id;

        if (this.tokenReport!==''){

          this.showReport();
        }
      }
    });

    });
  }

  showReport() {
    // Report's Secured Token
    let accessToken = this.tokenReport;

    // Embed URL
    let embedUrl = 'https://app.powerbi.com/reportEmbed?';

    // Report ID
    let embedReportId = this.reportId;

    // Configuration used to describe the what and how to embed.
    // This object is used when calling powerbi.embed.
    // This also includes settings and options such as filters.
    // You can find more information at https://github.com/Microsoft/PowerBI-JavaScript/wiki/Embed-Configuration-Details.
    let config= {
        type: 'report',
        accessToken: accessToken,
        embedUrl: embedUrl,
        id: embedReportId,
        settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: true
        }
    };

    // Grab the reference to the div HTML element that will host the report.
    let reportContainer = <HTMLElement>document.getElementById('reportContainer');

    // Embed the report and display it within the div container.
    let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    let report = powerbi.embed(reportContainer, config);

    // Report.off removes a given event handler if it exists.
    report.off("loaded");

    // Report.on will add an event handler which prints to Log window.
    report.on("loaded", function() {
        console.log("Loaded");
    });
  }

}
